import { useState, useEffect, ReactNode } from 'react'
import moment, { Moment } from 'moment-timezone'
import { get } from 'Gatekeeper'
import { useStateRef } from 'hooks/useStateRef'
import { getBeforeMaintenanceMessage, maintenanceMessage } from './messages'

export type Message = {
  title: string
  body: ReactNode
}

type UseMaintenanceIntervals = {
  showMaintenanceBanner: boolean
  message: Message
}

const noMaintenanceDays = ['Friday', 'Saturday', 'Sunday']
const maintenanceWindowDuration = get('enable_longer_maintenance_window')
  ? 60
  : 20

export function useMaintenanceIntervals(): UseMaintenanceIntervals {
  const [showMaintenanceBanner, updateMaintenanceBanner] = useStateRef(false)
  const [message, updateMessage] = useState<Message>(null)

  useEffect(() => {
    const activateMaintenance = (localStartTime?: Moment) => {
      updateMaintenanceBanner(true)
      const message = localStartTime
        ? getBeforeMaintenanceMessage(localStartTime.format('h:mm A'))
        : maintenanceMessage
      updateMessage(message)
    }
    const tick = () => {
      // Maintenance start time (in ET)
      const dailyMaintenanceStartTime = moment.tz(
        '5:00 pm',
        'h:mm a',
        'America/New_York',
      )

      const currentTime = moment()
      const currentDay = currentTime.format('dddd')

      const isNoMaintenanceDay = noMaintenanceDays.includes(currentDay)

      // Convert the ET start times to the user's local timezone
      const userTimezone = moment.tz.guess()
      const dailyStartLocal = dailyMaintenanceStartTime.tz(userTimezone)

      // Calculate the time difference between the current time and the start time of maintenance window
      const dailyDiff = dailyStartLocal.diff(currentTime, 'minutes')

      if (dailyDiff > 0 && dailyDiff <= 30 && !isNoMaintenanceDay) {
        activateMaintenance(dailyStartLocal)
      } else if (
        dailyDiff <= 0 &&
        dailyDiff >= -maintenanceWindowDuration &&
        !isNoMaintenanceDay
      ) {
        activateMaintenance()
      } else {
        updateMaintenanceBanner(false)
      }
    }
    const timer = setInterval(tick, 1000 * 60)
    tick()
    return () => clearTimeout(timer)
  }, [updateMaintenanceBanner, updateMessage])

  return {
    showMaintenanceBanner: showMaintenanceBanner.current,
    message,
  }
}
