import React from 'react'
import cx from 'classnames'
import Styles from './DimBackgroundLoader.module.scss'

export const DimBackgroundLoader = React.memo(
  ({
    dark = false,
    dim = false,
    size = 64,
  }: {
    dim?: boolean
    dark?: boolean
    size?: number
  }) => {
    const inner = (
      <div
        style={{ '--ldsRingSize': `${size}px` } as React.CSSProperties}
        className={cx(Styles.ldsRing, {
          [Styles.dark]: dark,
        })}
      >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    )
    if (dim) {
      return <div className={Styles.dim}>{inner}</div>
    }
    return inner
  },
)
