import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { Icon } from '@design-library/Icon'
import { selectAvailableCredit } from 'redux/selectors/selectAvailableCredit'
import { useMaintenanceIntervals } from './hooks/useMaintenanceIntervals'

import Styles from './Banner.module.scss'

const BannerImpl: FC = () => {
  const [bannerDismissed, setBannerDismissed] = useState(false)
  const percentageCreditAvailable = useSelector(selectAvailableCredit)

  const { message, showMaintenanceBanner } = useMaintenanceIntervals()

  const shouldShowExceedLimitBanner =
    percentageCreditAvailable <= 20 && percentageCreditAvailable >= 0

  return (
    <>
      {showMaintenanceBanner ? (
        <div className={Styles.maintenanceBanner}>
          <Icon color="#D9D46B" name="alert-triangle" />
          <span>
            {message.title} {message.body}
          </span>
        </div>
      ) : null}
      {shouldShowExceedLimitBanner && !bannerDismissed && (
        <div className={Styles.creditBanner}>
          <div>
            You have exceeded 80% of your credit limit. Please settle to
            increase available credit.
          </div>
          <div
            className={Styles.closeButton}
            onClick={() => setBannerDismissed(true)}
          >
            x
          </div>
        </div>
      )}
    </>
  )
}

export const Banner = React.memo(BannerImpl)
